<template>
<div class="scope">
    <div v-if="novel.buyed == 403">
        <img :src="novel.novel_cover" class="cover mb-4">
        <p> {{novel.novel_title}} </p>
        <hr>
        <p>คุณได้ซื้อนิยายเรื่องนี้ไปแล้ว</p>
    </div>
    <div v-else>
        <img :src="novel.novel_cover" class="cover mb-4">
        <p> {{novel.novel_title}} </p>
        <hr>
        <p>ราคาก่อนส่วนลด : {{novel.book_amount | numFormat}} ทอง</p>
        <p>ราคารวมส่วนลด : {{novel.book_amount_not_buy_after_discount | numFormat}} ทอง</p>
        <div class="myCoin mb-3">
            <table style="width:100%">
                <tr>
                    <td>ทองที่มีทั้งหมด</td>
                    <td><img src="./../../assets/price.png" width="18px" /> <span>{{novel.member_gold| numFormat}}</span></td>
                </tr>
            </table>
        </div>
        <b-alert :show="error" variant="warning">{{err_text}}</b-alert>
        <div v-if="ishide">
            <p class="warn">ยกเลิกการขายแล้ว</p>
        </div>
        <div v-else-if="goldEnough">
            <p class="addCoin" @click="buyBook(novel.novel_id)">ซื้อเลย</p>
        </div>
        <div class="text-center" v-else>
            <p class="warn">ทองของคุณไม่เพียงพอ</p>
            <p class="addCoin" @click="goToPayment">เติมทอง</p>
        </div>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import {
    mapActions,
    mapGetters
} from 'vuex'
import router from './../../router/index'

export default {
    props: ['novel'],
    data() {
        return {
            goldEnough: false,
            error: false,
            err_text: "",
            isLogin: localStorage.getItem('UserInfo') != null,
            ishide: false
        }
    },
    mounted() {
        //console.log('buy : ',this.novel)
        if (this.novel.goldEnough == true) {
            this.goldEnough = true
        }
        if(this.novel.ishide == true){
            this.ishide = true
        }
    },
    computed: {
        ...mapGetters({
            userInfo: 'GET_USER_INFO'
        })
    },
    methods: {
        ...mapActions(['SET_MEMBER_GOLD']),
        goToPayment() {
            router.push("/payment");
        },
        async buyBook(novel_id){
            const formData = new FormData();
            if (this.isLogin) {
                //console.log("login leaw")
                formData.append('auth_memberid', this.userInfo.auth_memberid);
                formData.append('auth_reference', this.userInfo.auth_token);
                formData.append('novel_id', novel_id);
                formData.append('action', 'buy');
            }
            try {
                await axios.post('https://api2.novelrealm.com/module/novel&submodule=buy_book', formData)
                    .then((res) => {
                        //console.log(res)
                        if (res.status == 200) {
                            this.SET_MEMBER_GOLD(res.data.member_gold)
                            location.reload()
                        }
                    })
            } catch (err) {
                //console.log('FAILURE!!' + err)
            }
        }
    }
}
</script>

<style scoped>
.scope {
    padding: 5% 10% 0.2% 10%;
    text-align: center;
}

.cover {
    border-radius: 10px;
    width: 6rem;
}

.myCoin {
    padding: 5px;
    border: 1px solid #EE806B;
    border-radius: 7px;
    text-align: center;
    width: 100%;
}

.warn {
    font-size: 14px;
    color: #ffffff;
    padding: 0px 5px;
    border-radius: 5px;
    background-color: #042446;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
}

.addCoin {
    color: #ffffff;
    font-weight: bold;
    padding: 7px 20px 7px 20px;
    border-radius: 8px;
    background-color: #EE806B;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.addCoin:hover {
    background-color: rgb(235, 116, 92);
    cursor: pointer;
}
</style>
